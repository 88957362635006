import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';

import { PRIMARY_SUBTITLE_COLOR, PRIMARY_TEXT_COLOR, SOCIAL_URLS, TEXT_FONT, TITLE_FONT } from '../constants';
import { Icon, Logo } from './shared';
import Link from './shared/link';
import { CURRENT_YEAR } from '../utils/date.utils';

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .contact {
        word-wrap: break-word;
    }
`;

const OverlinedCol = styled(Col)`
    margin-bottom: 50px;
    @media (min-width: 992px) {
        ${props => (props.showLine === true ? `border-top: 1px solid ${PRIMARY_TEXT_COLOR};` : null)}
    }
`;

const DescriptionText = styled.div`
    font-size: 14px;
    letter-spacing: -0.4px;
    color: ${PRIMARY_TEXT_COLOR};
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: ${TEXT_FONT};

    @media (max-width: 568px) {
        margin-bottom: 15px;
    }
    a {
        font-size: 14px;
        letter-spacing: -0.4px;
        color: #ebebeb;
        font-family: ${TEXT_FONT};
        white-space: pre;
    }

    a:hover {
        text-decoration: none;
        color: ${PRIMARY_SUBTITLE_COLOR};
    }

    @media (min-width: 992px) {
        ${props => (props.removePadding === true ? `margin-top: 1rem;` : null)}
    }
`;

const SubSectionTitle = styled.h5`
    font-size: 16px;
    letter-spacing: -0.4px;
    font-weight: bold;
    font-family: ${TITLE_FONT};
    color: ${PRIMARY_TEXT_COLOR};
    margin-bottom: 20px;
    @media screen and (orientation: landscape) {
        margin-bottom: 0;
        margin-top: 35px;
    }
    line-height: 40px;
`;

const SubSection = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;

const SubSectionItem = styled.li`
    & > a {
        font-size: 16px;
        letter-spacing: -0.4px;
        color: #ebebeb;
        font-family: ${TEXT_FONT};
    }

    & > a:hover {
        text-decoration: none;
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;

const Social = styled.div`
    margin-bottom: 0px;
    font-size: 30px;

    a {
        color: ${PRIMARY_TEXT_COLOR};
    }

    a:hover {
        text-decoration: none;
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;

const CenteredContainer = styled(Container)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const Footer = props => {
    return (
        <FooterWrapper>
            <CenteredContainer>
                <Row className={props.hideLogo ? 'd-none' : null}>
                    <OverlinedCol xs={11} />
                </Row>
                <Row>
                    <Col lg={4} md={12} xs={{ order: props.hideLogo ? 12 : null }} className="description">
                        <Link to={'/'}>
                            <Logo fill={PRIMARY_TEXT_COLOR} className={props.hideLogo ? 'd-none' : null} />
                        </Link>
                        <DescriptionText removePadding={props.hideLogo}>
                            <div>© {CURRENT_YEAR},&nbsp;Equaleyes d.o.o.</div>
                            <div>
                                <Link to={'/terms'}>Terms and Conditions</Link> | <Link to={'/privacy'}>Privacy statements</Link> |{' '}
                                <Link to={'/cookies'}>Cookie policy</Link>
                            </div>
                        </DescriptionText>
                        <Social>
                            {/*<Icon type="facebook" href={SOCIAL_URLS.facebook} />*/}
                            <Icon type="twitter" href={SOCIAL_URLS.twitter} />
                            <Icon type="linkedin" href={SOCIAL_URLS.linkedin} />
                            <Icon type="instagram" href={SOCIAL_URLS.instagram} />
                            <Icon type="github" href={SOCIAL_URLS.github} />
                        </Social>
                    </Col>
                    {props.hideLogo ? null : (
                        <>
                            <Col xs={{ size: props.hideLogo ? 5 : 6, order: props.hideLogo ? null : 12 }} md={3} lg={2} className="mt-1">
                                <SubSectionTitle>Offices</SubSectionTitle>
                                <SubSection>
                                    <SubSectionItem>
                                        <a
                                            href="https://maps.app.goo.gl/BRj8tRTWWX8S2JzD7"
                                            target="_blank"
                                            rel="noopener noreferrer nofollow"
                                        >
                                            Ljubljana, SI
                                        </a>
                                    </SubSectionItem>
                                    <SubSectionItem>
                                        <a
                                            href="https://maps.app.goo.gl/qfCr1UabADEGQ11k8"
                                            target="_blank"
                                            rel="noopener noreferrer nofollow"
                                        >
                                            Maribor, SI
                                        </a>
                                    </SubSectionItem>
                                </SubSection>
                            </Col>
                            <Col md={3} xs={6} lg={{ size: 2, offset: 1 }} className="mt-1">
                                <SubSectionTitle>Company</SubSectionTitle>
                                <SubSection>
                                    <SubSectionItem>
                                        <Link to={'/about'}>About us</Link>
                                    </SubSectionItem>
                                    <SubSectionItem>
                                        <Link to={'/services'}>Services</Link>
                                    </SubSectionItem>
                                    <SubSectionItem>
                                        <Link to={'/case-studies'}>Case studies</Link>
                                    </SubSectionItem>
                                    <SubSectionItem>
                                        <Link to={'/careers'}>Careers</Link>
                                    </SubSectionItem>
                                    <SubSectionItem>
                                        <Link to={'/contact'}>Contact</Link>
                                    </SubSectionItem>
                                </SubSection>
                            </Col>
                        </>
                    )}
                    <Col xs={props.hideLogo ? 8 : 5} md={4} lg={2} xl={3} className="contact mt-1">
                        <SubSectionTitle>Contact</SubSectionTitle>
                        <SubSection>
                            <SubSectionItem>
                                <a href="tel:+38615186681">SI: +386&nbsp;1&nbsp;518&nbsp;66&nbsp;81</a>
                            </SubSectionItem>
                            <SubSectionItem>
                                <a href="mailto:contact@equaleyes.com">contact@equaleyes.com</a>
                            </SubSectionItem>
                        </SubSection>
                    </Col>
                </Row>
            </CenteredContainer>
        </FooterWrapper>
    );
};

Footer.propTypes = {
    hide: PropTypes.bool,
};
